<template>
    <el-form-item :label="$t('calendar.start_date')">
        <el-input v-model="selectedDate" prefix-icon="el-icon-date" disabled />
    </el-form-item>
</template>
<script>
export default {
    props: {
        selectedDateProp: {
            type: Object,
            default() {
                return {
                    selectedDate: null,
                };
            },
        },
    },
    computed: {
        selectedDate() {
            return this.selectedDateProp.format('DD.MM.YYYY');
        },
    },
};
</script>
